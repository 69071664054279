import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { cn } from 'class-merge';
import { delay } from 'lodash';
import { CloseOutlined } from '@packages/icons-react';
import type { NotificationRearrangeInterface } from '~libs/get-notification-rearrange';
import NotificationCard from '~components/notification/notification-card';

interface NotificationProps {
  duration?: number;
}

const Notification = forwardRef((props: NotificationProps, ref) => {
  const { duration = 8000 } = props;
  const [visibleContent, setVisibleContent] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<
    NotificationRearrangeInterface[]
  >([]);

  const onStartProgress = (): void => {
    delay(() => {
      handleExpireNotification();
    }, duration);
  };

  useImperativeHandle(ref, () => ({
    dispatchNotify(notifies: NotificationRearrangeInterface[]) {
      setVisibleContent(true);
      onStartProgress();
      setNotifications(notifies);
    },
  }));

  const handleExpireNotification = (): void => {
    setVisibleContent(false);
  };

  return (
    <div
      className={cn(
        'top-18 absolute left-0 right-0 mx-4 min-w-[250px] rounded-lg text-white transition duration-300 md:left-auto md:right-6 md:mx-0 md:w-full md:max-w-[370px]',
        visibleContent ? 'z-40 opacity-100' : 'invisible opacity-0',
      )}
    >
      {notifications.map((notify, i) => (
        <div className="relative mb-4" key={i}>
          <CloseOutlined
            className="absolute right-4 top-6 cursor-pointer text-sm text-black"
            onClick={() => {
              setVisibleContent(false);
            }}
          />
          <NotificationCard notifyInfo={notify} />
        </div>
      ))}
    </div>
  );
});

Notification.displayName = 'Notification';
export default Notification;
