import type { CSSProperties, FC, ReactNode } from 'react';
import React from 'react';
import { cn } from 'class-merge';

interface FieldSkeletonLoadingItemProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const FieldSkeletonLoadingItem: FC<FieldSkeletonLoadingItemProps> = (
  props,
) => {
  const { className = '', style, children } = props;
  return (
    <div
      className={cn(
        'bg-color-evaluated h-[18px] w-[20%] rounded-md',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default FieldSkeletonLoadingItem;
