import { cn } from 'class-merge';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY } from '~constants/etc';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';

const CurrencyWidget: FC<{
  className?: string;
}> = ({ className }) => {
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet?.objectId || '',
  );
  const { t } = useTranslation('etc');
  return (
    <span className={cn('text-base', className)}>
      {t(`currency-${walletInfo?.currency || DEFAULT_CURRENCY}` as any)}
    </span>
  );
};

export default CurrencyWidget;
