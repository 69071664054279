import { isArray } from 'radash';
import type { AxiosResponse } from 'axios';

const replaceString = (value: string): string => {
  return value.replaceAll(' ', '-');
};

const getErrorMessage = (errorResponse: AxiosResponse): string => {
  const message = errorResponse.data.message;
  if (!message) {
    return 'general-error-message';
  }
  return isArray(message) ? replaceString(message[0]) : replaceString(message);
};

export default getErrorMessage;
