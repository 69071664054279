import { proxied } from 'radash';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { CloseOutlined, DollarPaperOutlined } from '@packages/icons-react';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { cn } from 'class-merge';
import { EnumActionNotification } from '~constants/notifiaction';
import { MoneyWithDecimalFormat } from '~constants/etc';
import { WalletProps } from '~containers/wallet/wallet-container';
import request from '~libs/request';
import HttpErrorHandler from '~components/http-error-handler';

interface NotificationCardProps {
  className?: string;
  notifyInfo: any;
  isShowCloseIcon?: boolean;
}

interface FinancialNotificationItemProps {
  isShowCloseIcon: boolean;
  content: ReactNode;
  handleRemove: (values: any) => void;
}

const FinancialNotificationItem: FC<FinancialNotificationItemProps> = (
  props,
) => {
  const { isShowCloseIcon, handleRemove, content } = props;
  return (
    <div className="flex space-x-2 rounded-lg bg-white p-4 text-xl font-bold text-black">
      <div className="pt-1">
        <DollarPaperOutlined className="text-base md:text-lg" />
      </div>
      <div className="flex-grow">
        <div className="flex items-center justify-between">
          <p className="text-base font-semibold md:text-lg">ประกาศจากทางระบบ</p>
          {isShowCloseIcon ? (
            <CloseOutlined
              className="cursor-pointer text-sm"
              onClick={handleRemove}
            />
          ) : null}
        </div>
        {content}
      </div>
    </div>
  );
};

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const { notifyInfo, isShowCloseIcon = false } = props;
  const walletProps = WalletProps[notifyInfo.data?.wallet || 'credit'];
  const isDeposit = [
    EnumActionNotification.DepositSuccess,
    EnumActionNotification.DepositCancelled,
  ].includes(notifyInfo.action);

  const requests = async (values): Promise<void> => {
    try {
      await request({
        method: 'PUT',
        url: '/users/notifications',
        data: values,
      });
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleRemove = (values): void => {
    void requests({
      key: values.key,
      notificationId: values.id,
      index: values.index,
      visibility: 'hidden',
    });
  };

  const componentsNotificationCards = proxied(
    (prop: EnumActionNotification) => {
      switch (prop) {
        case EnumActionNotification.DepositSuccess:
        case EnumActionNotification.WithdrawalSuccess:
          return (
            <FinancialNotificationItem
              content={
                <div className="mt-1 text-xs font-normal md:text-sm">
                  <p>
                    ท่านทำการ{isDeposit ? 'ฝาก' : 'ถอน'}เครดิตมูลค่า{' '}
                    <span
                      className={cn(
                        'font-semibold',
                        isDeposit
                          ? 'text-color-success'
                          : 'text-textColor-color-error',
                      )}
                    >
                      {numeral(notifyInfo.data.amount).format(
                        MoneyWithDecimalFormat,
                      )}
                    </span>{' '}
                    บาท
                  </p>
                  <div>
                    ผ่าน{' '}
                    <span
                      className={cn('font-semibold', walletProps.textColor)}
                    >
                      {walletProps.text}
                    </span>
                  </div>
                  <div className="text-color-secondary mt-2 text-right text-[10px] font-semibold opacity-50">
                    {dayjs(notifyInfo.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </div>
              }
              handleRemove={handleRemove}
              isShowCloseIcon={isShowCloseIcon}
            />
          );
        case EnumActionNotification.DepositCancelled:
        case EnumActionNotification.WithdrawalCancelled:
          return (
            <FinancialNotificationItem
              content={
                <div className="mt-1 text-xs font-normal md:text-sm">
                  <p>
                    รายการ{isDeposit ? 'ฝาก' : 'ถอน'}เครดิตมูลค่า{' '}
                    <span className="text-textColor-color-error font-semibold">
                      {numeral(notifyInfo.data.amount).format(
                        MoneyWithDecimalFormat,
                      )}
                    </span>{' '}
                    บาท
                  </p>
                  <div>
                    ผ่าน{' '}
                    <span
                      className={cn('font-semibold', walletProps.textColor)}
                    >
                      {walletProps.text}
                    </span>{' '}
                    ถูกยกเลิก
                  </div>
                  <div className="text-color-secondary mt-2 text-right text-[10px] font-semibold opacity-50">
                    {dayjs(notifyInfo.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </div>
              }
              handleRemove={handleRemove}
              isShowCloseIcon={isShowCloseIcon}
            />
          );
        default:
          return <div />;
      }
    },
  );

  return componentsNotificationCards[notifyInfo.action];
};

export default NotificationCard;
