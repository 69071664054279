import type { FC } from 'react';
import { cn } from 'class-merge';
import { useWalletInfo } from '~hooks/use-wallet';
import { useUserInfo } from '~hooks/use-user';
import BalanceWidget from '~components/widget/balance-widget';
import CurrencyWidget from '~components/widget/currency-widget';

interface WalletBalanceWidgetProps {
  className?: string;
}

const WalletBalanceWidget: FC<WalletBalanceWidgetProps> = ({ className }) => {
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet?.objectId || '',
  );
  return (
    <span className={cn('text-color text-[32px] font-bold', className)}>
      <BalanceWidget balance={walletInfo?.balance} /> <CurrencyWidget />
    </span>
  );
};

export default WalletBalanceWidget;
