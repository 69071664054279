import type { FC, ReactNode } from 'react';
import {
  CheckOutlined,
  CylindOutlined,
  GiftBoxOutlined,
  RerollBoxOutlined,
  WalletOutlined,
} from '@packages/icons-react';
import { useDialog } from 'hooks/use-dialog';
import { cn } from 'class-merge';
import { proxied } from 'radash';
import { isNull, keys, omitBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useWalletInfo } from '~hooks/use-wallet';
import { WalletEnum } from '~constants/wallet';
import SkeletonLoading from '~components/loading/skeleton-loading';
import WalletBalanceWidget from '~components/widget/wallet-balance-widget';
import BalanceWidget from '~components/widget/balance-widget';
import appConfig from '~build-config/config.json';

interface WalletWidgetProps {
  extra?: ReactNode;
  wallets: Record<WalletEnum, string> | undefined;
  activeWalletId: string;
  handleSwitchWallet: (wallet: string) => void;
}

export const WalletProps = proxied((type: WalletEnum) => {
  switch (type) {
    case WalletEnum.CREDIT:
      return {
        key: 'credit',
        text: <Trans i18nKey="wallets:credit-wallet" />,
        icon: <WalletOutlined className="text-xl" />,
        className: 'bg-[#E3AE7E]',
        textColor: 'text-[#E3AE7E]',
      };
    case WalletEnum.BONUS:
      return {
        key: 'bonus',
        text: <Trans i18nKey="wallets:bonus-wallet" />,
        icon: <GiftBoxOutlined className="text-xl" />,
        className: 'bg-[#867EE3]',
        textColor: 'text-[#867EE3]',
      };
    case WalletEnum.REVENUE:
      return {
        key: 'revenue',
        text: <Trans i18nKey="wallets:revenue-wallet" />,
        icon: <CylindOutlined className="text-xl" />,
        className: 'bg-[#7EE3B9]',
        textColor: 'text-[#7EE3B9]',
      };
  }
});

export const WalletBalance: FC<{
  className?: string;
  walletId: string;
}> = (props) => {
  const { className, walletId } = props;
  const { data: apiWallet, isLoading } = useWalletInfo(walletId);

  if (isLoading) {
    return (
      <span className={className} title="balance">
        ...
      </span>
    );
  }

  return <BalanceWidget balance={apiWallet?.balance} className={className} />;
};

const WalletContainer: FC<WalletWidgetProps> = (props) => {
  const { t } = useTranslation('wallets');
  const {
    extra = <p className="text-color font-bold">{t('wallet-lists')}</p>,
    activeWalletId,
    wallets,
    handleSwitchWallet,
  } = props;
  const { data: walletInfo } = useWalletInfo(activeWalletId, {
    suspense: true,
  });
  const [dialog, dialogContext] = useDialog();
  const walletType = walletInfo?.type || WalletEnum.CREDIT;

  const handleSelectWallet = (value: string): void => {
    dialog.destroy();
    handleSwitchWallet(value);
  };

  const handle = (): void => {
    dialog.content({
      maskable: true,
      className: 'w-full h-fit p-0 rounded-lg flex items-center',
      children: (
        <div className="bg-color-evaluated rounded-default flex h-full w-full flex-col gap-4 p-4">
          <div className="flex items-center gap-2 text-base font-semibold sm:text-xl">
            <RerollBoxOutlined className="text-switch text-xl" />{' '}
            {t('switch-wallet')}
          </div>
          <div className="flex flex-col gap-4">
            {keys(omitBy(wallets, (v: string) => isNull(v))).map((res) => (
              <div
                aria-hidden
                className={cn(
                  'hover:ring-color-primary rounded-default flex cursor-pointer items-center gap-4 p-4 hover:ring-1',
                  appConfig.customClassName?.bgSwitchWallet ||
                    'bg-color-button-active',
                )}
                key={res}
                onClick={() => {
                  handleSelectWallet(wallets?.[res]);
                }}
                role="button"
              >
                <div
                  className={cn(
                    'flex h-[36px] w-[36px] items-center justify-center rounded-lg bg-white',
                    WalletProps[res]?.className,
                  )}
                >
                  {WalletProps[res]?.icon}
                </div>
                <div className="text-color-secondary flex grow flex-col text-sm font-medium">
                  <WalletBalance
                    className="text-color text-xl font-bold"
                    walletId={wallets?.[res]}
                  />
                  {WalletProps[res]?.text}
                </div>
                {activeWalletId === wallets?.[res] ? (
                  <div className="bg-color-success rounded-full p-1 text-xs text-white">
                    <CheckOutlined />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ),
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {dialogContext}
      <div className="flex items-end justify-between">
        {extra}
        <div
          aria-hidden
          className="text-color flex items-center gap-2 text-sm"
          onClick={handle}
          role="button"
          tabIndex={-1}
        >
          <RerollBoxOutlined className="text-switch" /> {t('switch-wallet')}
        </div>
      </div>
      {walletInfo ? (
        <div
          className={cn(
            'rounded-default relative flex min-h-[120px] w-full flex-col justify-center gap-2 p-6',
            appConfig.customClassName?.bgWalletInfo || 'bg-color-evaluated',
            appConfig.customClassName?.bgWalletInfo && 'bg-opacity-50',
          )}
        >
          <div className="text-color flex items-center gap-2">
            <div
              className={cn(
                'flex h-[36px] w-[36px] items-center justify-center rounded-lg',
                WalletProps[walletType].className,
              )}
            >
              {WalletProps[walletType].icon}
            </div>
            {WalletProps[walletType].text}
          </div>
          <WalletBalanceWidget />
          {appConfig.imageCoverWalletInfo ? (
            <Image
              alt="dsfsdfd"
              className="absolute bottom-0 right-0 object-contain object-right"
              draggable={false}
              fill
              src={appConfig.imageCoverWalletInfo}
              unoptimized
            />
          ) : null}
        </div>
      ) : (
        <div className="bg-color-button flex w-full animate-pulse flex-col justify-center gap-4 rounded-lg p-6">
          <div className="flex items-center gap-2">
            <SkeletonLoading className="bg-color-dark h-[36px] w-[36px] rounded-lg" />
            <SkeletonLoading className="bg-color-dark w-[100px] rounded-lg" />
          </div>
          <SkeletonLoading className="bg-color-dark h-[40px] w-[200px] rounded-lg" />
        </div>
      )}
    </div>
  );
};

export default WalletContainer;
