import React from 'react';
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';
import { appsignal } from '../appsignal';

const DefaultFallbackComponent = (): JSX.Element => (
  <div>Uh oh! There was an error {':('}</div>
);

export const ErrorBoundary: React.FC<{
  appName?: string;
  children: React.ReactNode;
  fallbackComponent?: React.ComponentType;
}> = (props) => {
  const { children, appName = 'unknown', fallbackComponent } = props;
  const FallbackComponent = fallbackComponent ?? DefaultFallbackComponent;
  return (
    <AppSignalErrorBoundary
      instance={appsignal}
      fallback={(error: Error) => {
        void appsignal.sendError(error, (span) => {
          span.setTags({
            app_name: appName,
            component: 'ErrorBoundary',
          });
        });
        return <FallbackComponent />;
      }}
    >
      {children}
    </AppSignalErrorBoundary>
  );
};
