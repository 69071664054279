import {
  BanksOutlined,
  CallCenterOutlined,
  HeartTagOutlined,
  HomeOutlined,
  MegaphoneOutlined,
} from '@packages/icons-react';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { cn } from 'class-merge';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import PageUrls from '~constants/page-urls';
import appConfig from '~build-config/config.json';

interface NavItemProps {
  icon: ReactNode;
  text: ReactNode;
  href: string;
  className?: string;
  isActive?: boolean;
}

const NavigationLists: (NavItemProps & { key: string })[] = [
  {
    key: '/affiliate',
    icon: <BanksOutlined className="text-xl sm:text-2xl" />,
    text: 'affiliate-menu',
    href: PageUrls.AFFILIATE,
  },
  {
    key: '/financial/deposit/promotion',
    icon: <HeartTagOutlined className="text-xl sm:text-2xl" />,
    text: 'promotion-menu',
    href: PageUrls.FINANCIAL_DEPOSIT_PROMOTION,
  },
  {
    key: '/',
    icon: <HomeOutlined className="text-xl sm:text-3xl" />,
    text: 'homepage-menu',
    href: PageUrls.HOME,
    // className: '-top-2 sm:-top-4 rounded-lg text-xs sm:text-base sm:pt-2',
  },
  {
    key: '/announce',
    icon: <MegaphoneOutlined className="text-xl sm:text-2xl" />,
    text: 'announcement-menu',
    href: PageUrls.ANNOUNCE,
  },
  {
    key: '/contact',
    icon: <CallCenterOutlined className="text-xl sm:text-2xl" />,
    text: 'contact-menu',
    href: PageUrls.CONTACT,
  },
];

const NavigationItem: FC<NavItemProps> = (props) => {
  const { icon, text, href, className, isActive } = props;
  return (
    <div className="relative h-full">
      <Link
        className={cn(
          'hover:text-color-primary bg-color-bottom-navbar border-b-color-primary text-color relative mt-0 grid h-20 w-full grid-rows-2 items-center justify-center border-b-0 p-2 text-center text-xs transition-all duration-150',
          isActive &&
            'text-color-primary bg-color-bottom-navbar-active -mt-4 h-24 rounded-t-lg border-b-[5px]',
          className,
        )}
        href={href}
      >
        <div className="flex w-full items-center justify-center">
          <div className="relative flex h-[30px] w-[30px] items-center justify-center">
            {icon}
          </div>
        </div>
        <div className="truncate text-xs">{text}</div>
      </Link>
    </div>
  );
};

const FooterNavigation: FC = () => {
  const { i18n } = useTranslation();
  const pathname = usePathname();
  const [activeKey, setActiveKey] = useState<string>();
  const { t } = useTranslation('menus');

  useEffect(() => {
    const regex = new RegExp(`^/${i18n.language}`);
    const path = pathname.replace(regex, '');
    setActiveKey(path || '/');
  }, [pathname, i18n.language]);

  return (
    <nav
      className={cn(
        'bg-color-evaluated fixed bottom-0 z-20 grid h-[80px] w-full max-w-screen-md grid-cols-5 items-center justify-center lg:max-w-[750px] xl:max-w-screen-sm',
        appConfig.customClassName?.wrapperFooterNav ||
          'shadow-[rgb(0,0,0,.1)_0px_-8px_16px_8px] sm:shadow-[rgb(0,0,0,.3)_0px_-12px_24px_12px]',
      )}
    >
      {NavigationLists.map((navigation) => (
        <NavigationItem
          className={navigation.className}
          href={navigation.href}
          icon={navigation.icon}
          isActive={activeKey === navigation.key}
          key={navigation.key}
          text={t(navigation.text as any)}
        />
      ))}
    </nav>
  );
};

export default FooterNavigation;
