'use client';

import type { FC, ReactNode } from 'react';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { Socket } from 'socket.io-client';
import io from 'socket.io-client';
import { parseCookies } from 'nookies';
import { useUserInfo } from '~hooks/use-user';
import type { NotificationRearrangeInterface } from '~libs/get-notification-rearrange';
import getNotificationRearrange from '~libs/get-notification-rearrange';

interface RefNotificationInterface {
  current: {
    dispatchNotify: (notifies: NotificationRearrangeInterface[]) => void;
  };
}

interface SocketContextInterface {
  socket: Socket;
  refNotification: RefNotificationInterface;
}

interface WebSocketProviderProps {
  children?: ReactNode;
}

const WebSocketContext = createContext<SocketContextInterface>(
  {} as SocketContextInterface,
);

export const WebSocketProvider: FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<any>(null);
  const { data: userInfo } = useUserInfo();
  const refNotification: any = useRef();
  const cookies = parseCookies();
  const jwtToken = cookies.access_token || '';
  const wsServicePath = process.env.WS_SERVICE || '';
  const socketIO: Socket = io(wsServicePath, {
    transports: ['websocket'],
    autoConnect: false,
    path: '/ws/',
    reconnection: true,
    reconnectionAttempts: 10,
    query: {
      token: jwtToken,
    },
  });

  useEffect(() => {
    socketIO.connect();
    if (!socket) {
      socketIO.on('connect', () => {
        setSocket(socketIO);
      });
    }
    socketIO.on('disconnect', () => {
      setSocket(null);
    });
  }, []);

  const handleFinancialNotify = (notify): void => {
    const notifies = getNotificationRearrange(notify);
    if (typeof refNotification?.current?.dispatchNotify === 'function') {
      const audio = document.getElementById(
        'audio-notification',
      ) as HTMLAudioElement;
      void audio.play();
      refNotification.current.dispatchNotify(notifies);
    }
  };

  useEffect(() => {
    if (!socket || !userInfo) {
      return;
    }
    socket.emit('subscribe:user_financial_event');
    socket.on('subscribe:user_financial_event', (notify) => {
      handleFinancialNotify(notify);
    });
  }, [socket, userInfo]);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        refNotification,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(WebSocketContext);
};
