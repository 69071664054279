import type { AxiosError } from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import i18n from '~i18n';
import ErrorDialog from '~components/dialog/error-dialog';
import getErrorMessage from '~libs/get-error-message';

const HttpErrorHandler = (
  error: AxiosError<{ [key: string]: any; message: any }>,
): void => {
  const t = i18n.t;
  let element = document.getElementById('error-handler');
  if (!element) {
    const div = document.createElement('div');
    div.setAttribute('id', 'error-handler');
    element = document.body.appendChild(div);
  }
  const container = createRoot(element);

  let errorProps: {
    title: string | React.ReactElement;
    content: string | React.ReactElement;
  } = {
    title: t('errors:error-occurred'),
    content: `กรุณาลองใหม่อีกครั้งในภายหลัง ${error.message}`,
  };

  if (error.isAxiosError && error.response) {
    const errorResponse = error.response;
    const errorStatus = errorResponse.status;

    const errorMappings: Record<
      number | string,
      { title: string; content: string }
    > = {
      500: {
        title: t('errors:internal-server-error-title'),
        content: t('errors:general-error-message'),
      },
      429: {
        title: t('errors:internal-bad-request-title'),
        content: t('errors:general-rate-limit-error-message'),
      },
      401: {
        title: t('errors:internal-system-error-title'),
        content: t('errors:username-or-password-not-matched'),
      },
      403: {
        title: t('errors:internal-system-error-title'),
        content: t('errors:general-forbidden-error-message'),
      },
      404: {
        title: t('errors:internal-system-error-title'),
        content: errorResponse.data.message,
      },
      400: {
        title: t('errors:internal-bad-request-title'),
        content: t(`errors:${getErrorMessage(errorResponse)}` as any),
      },
      unknown: {
        title: t('errors:internal-system-error-title'),
        content: t('errors:please-try-again-later'),
      },
    };
    errorProps = errorMappings[errorStatus];
  }

  const close = (): void => {
    container.unmount();
  };

  const render = (props): void => {
    container.render(<ErrorDialog visible {...props} onClose={close} />);
  };

  render(errorProps);
};

export default HttpErrorHandler;
