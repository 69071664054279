'use client';

import type { FC, PropsWithChildren } from 'react';
import { useSession } from 'next-auth/react';
import { ErrorBoundary } from '@packages/sentry/components';
import { cn } from 'class-merge';
import Header from '~components/layout/header';
import FooterNavigation from '~components/layout/footer-nav';
import Notification from '~components/notification';
import { useSocket } from '~context/socket';

const DefaultLayout: FC<
  PropsWithChildren & { locale: string; isShowHeader?: boolean }
> = ({ children, locale, isShowHeader = true }) => {
  const { data: session } = useSession();
  const { refNotification } = useSocket();
  const isUnauthorized = Boolean(session);

  return (
    <ErrorBoundary appName="123bet-vip">
      <div
        className={cn(
          'bg-color-layout relative flex h-full min-h-screen flex-col items-center overflow-hidden text-white',
          isShowHeader ? 'py-[90px]' : 'py-6',
        )}
      >
        <Notification ref={refNotification} />
        {isShowHeader ? (
          <Header isVisibleNavbar={isUnauthorized} locale={locale} />
        ) : null}
        {children}
        {isUnauthorized ? <FooterNavigation /> : null}
      </div>
    </ErrorBoundary>
  );
};

export default DefaultLayout;
